.root {
  width: '100%';
  margin-top: "3px";
  overflow-x: 'auto';
}

.table {
  min-width: 650;
}

.actions span {
  cursor: pointer;
}

.inactive {
  opacity: 0.3;
}

.ccenter {
  text-align: center !important;
}

.green {
  color: green;
}

.red {
  color: #943734;
}

.action-icons {
  cursor: pointer;
}

.refresh {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
}
