@import "./app.variables";

#page-root {
  display: flex;
  width: 100%;
}
.cloud-not-installed {
  background: var(--upper-gradient);
  width: 100%;
  color: white;
  margin: -6px 0 0 0;
  display: flex;
  align-items: center;

  > div {
      padding: size(1.5) size(4);
      display: flex;
      align-items: center;
  }

  svg {
      padding-right: size(1);
  }
}