@import "../../app.variables";

.create-queue {
  .actions {

    margin: 0 0 30px 0;
    color: white;
    float: right;
  }

  .list {
    width: 100%;
    display: table;
  }

  .action-button {
    color: #943733 !important;
    border: 1px solid #943733 !important;
  }

  .action-button:hover,
  .action-button:active {
    background-color: #943733 !important;
    color: white !important;
  }

  .toggle-integration {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-items: flex-end;
    margin: 0 0 0 size(2);

    span {
      font-size: 15px;
      color: var(--text);
    }

    label {
      margin: 0 size(1) 0 0;
    }
  }
}
