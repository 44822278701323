@import "../../app.variables";

.generate-keys {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  .button {
    margin: size(1) 0 0 0 !important;
  }
}
