@import "../../app.variables";

.list-media {
  .icons {
    padding: 5px 0 0 0;
  }
  .setup-box {
    margin: size(4) 0 0 0;
  }
  .disabled,
  .inactive {
    opacity: 0.2;
  }
  .break-long-field {
    max-width: 500px;
    cursor: pointer;
  }
  span.pointer {
    cursor: pointer;
  }
  svg {
    margin-left: 15px;
    path {
      fill: var(--color-gray);
    }
  }

  div.view-media {
    .modal-body {
      padding: 0;
      video {
        width: 100%;
      }
    }
  }
}
